
import { defineComponent, PropType, ref } from "vue";
import { useStore } from "vuex";
import useModel from "@/hooks/useModel";
import { getEntityList } from "@/API/user";
export default defineComponent({
  props: {
    isDefault: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String as PropType<any>,
    },
    isAll: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const _value = useModel(props, "value");

    const { state } = useStore();
    const entityList = ref([]);

    const getAllEntityList = () => {
      return new Promise((resolve, reject) => {
        getEntityList().then((data: any) => {
          if (props.isAll) {
            data.push({
              entityName: "ALL",
            });
          }
          resolve(data);
        });
      });
    };
    const initEntityList = async () => {
      if (state.user.entityList.length > 0) {
        entityList.value = state.user.entityList;
      } else {
        entityList.value = (await getAllEntityList()) as any;
        console.log(entityList.value);
      }
    };
    initEntityList();

    return {
      entityList,
      _value,
    };
  },
});
